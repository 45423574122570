$gc-color: #5e9ab8;

.navbar-footer-area {
  border-top: solid 1px #cccccc;
  font-size: 20px;

  a {
    color: #cccccc;

    &.active {
      color: $gc-color;
    }
  }

  .create-appointment-btn {
    cursor: pointer;
    margin-top: -15px;
    
    .create-btn-circle {
      background-color: $gc-color;
      color: white;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      font-weight: bold;
      font-size: 22px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
      transition: all 0.2s ease;
      
      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
        background-color: lighten($gc-color, 5%);
      }
      
      &:active {
        transform: translateY(0px);
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
      }
    }
  }
}

@import './components/index.scss';
@import '~video-react/styles/scss/video-react';

html {
  height: 100vh;
  scroll-behavior: smooth;
  scroll-padding-top: 130px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-wrap: break-word;
  appearance: none;
  -webkit-appearance: none;
}

body {
  font-family: 'Roboto', sans-serif !important;
}

// Footer
.footer {
  width: 100%;
  align-items: center;
  padding: 0px !important;

  ul,
  li {
    text-decoration: none;
    list-style: none;
  }

  a {
    font-size: 16px;
    color: white;
    transition: 0.1s all ease-in-out;
    &:hover {
      text-decoration: none;
      color: $primary;
      cursor: pointer;
      transition: 0.1s all ease-in-out;
    }
  }

  @include mobile {
    margin-right: -15px;
  }

  h5 {
    color: #cfe0ea;
    font-size: 20px;
  }

  p {
    color: white;
    font-size: 16px;
    margin-bottom: 2px;
  }

  .logo-bussiness {
    display: flex;
    width: 20%;
    min-width: 25rem;
    justify-content: space-around;
  }
  .black-footer {
    padding: 30px 20px 40px 20px;
    min-height: 200px;
    background-color: #424242;
    align-content: flex-start;
  }
  .white-footer {
    padding: 20px 10px 10px 10px;
    min-height: 20px;
    background-color: white;
    align-content: flex-start;
    border-top: 1px solid #eaeaea;

    a {
      color: #333333 !important;
      font-size: 12px;
    }

    p {
      color: #333333;
      font-size: 12px;
    }
  }
  .signature {
    text-align: center;
    background-color: #cfe0ea;
    padding: 20px 10px 10px 10px;
    font-size: 12px;
    width: 100%;
    margin: 0px;

    p {
      color: #333333 !important;
    }
  }

  @media only screen and (max-width: 600px) {
  }

  .center-container {
    display: flex;
    width: 20%;
    justify-content: space-around;
    align-items: flex-start;

    .logo-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.main {
        width: 100%;
        @include mobile {
          justify-content: left;
        }

        .logo-img {
          width: 60%;
          max-width: 15rem;
          @include mobile {
            max-width: 100%;
            padding: 20px 0px;
          }
        }
      }

      &.secondary {
        width: 100%;

        .logo-img {
          width: 60%;
          max-width: 5rem;
        }
      }

      .logo-img {
        display: block;
      }
    }
  }

  // Mobile
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    height: 8rem !important;
    justify-content: space-around;
    padding: 0.5rem 0;

    .center-container {
      flex-direction: column;
      align-items: center;
      width: 100vw;

      .logo-container {
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .copyright {
          margin: 0.3rem 0.5rem 0.3rem 0;
        }

        &.secondary {
          .logo-img {
            max-width: 10%;
          }
        }
      }
    }
  }
}

.footer-icon {
  svg {
    fill: white;

    &:hover {
      fill: $primary;
      transform: translate(0px, -7px);
      cursor: pointer;
      transition: 0.1s all ease-in-out;
    }
  }
}

// Buttons
.btn-secondary {
  color: white;

  &:hover {
    color: white;
    background-color: lighten($secondary, 10%);
  }
}

.btn-warning {
  color: white;

  &:disabled {
    color: white;
  }
}

.btn-link {
  &:hover {
    opacity: 0.7;
  }
}

.btn-info {
  color: white;
}

//Margins

.margin-auto {
  margin-right: auto;
  margin-left: auto;
}

//Navbar

//Responsive Navbar

.responsive-navbar {
  position: absolute;
  width: 100%;
  top: 3rem;
  left: 0;
  box-shadow: 0px 50px 45px #4949496e;
  background-color: white !important;
  transition: 0.3s all ease-in-out;

  .accordion-card {
    background: transparent;
    border-color: transparent;
  }

  .card-header {
    background: transparent;
    padding: 0;
  }
}

.responsive-navbar-accordion {
  background: transparent;

  .accordion-options-bg {
    background-color: #f3f3f3;
  }
}

//LoggedAdmin

.nav-tab {
  min-width: 60px;
  height: 3.5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.5rem;
  font-size: 14px;
  color: $gray-700;
  border-bottom: 4px solid transparent;
  transition: all 0.2s ease-in-out;

  &.responsive {
    border-bottom: 0px solid transparent;
    font-size: 1rem;

    &:hover,
    &:focus {
      height: 3.5rem !important;
      color: $primary !important;
      font-weight: 700;
      border-bottom: 0px solid transparent;
    }
  }

  &:hover,
  &:focus {
    height: 3.5rem !important;
    color: $primary !important;
    border-bottom: 4px solid $primary;
  }

  .basic-nav-dropdown-link {
    &:hover,
    &:focus {
      color: $primary !important;
    }
  }
}

.nav-tab-child {
  height: 2rem;
  font-size: 0.9rem;
  color: $gray-700;
  background-color: transparent;
  min-width: 8rem;
  height: 1rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1.5rem;
}

.navbar {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 3.5rem;
  background-color: white !important;
  border-bottom: 1px solid #eaeaea;

  @media only screen and (max-width: 600px) {
    padding: 0 1rem;
    justify-content: space-between;
    width: 100%;
  }

  .navbar-brand {
    display: flex;
    align-items: center;
    height: auto;
    width: 10rem;
    height: 100%;
    overflow: hidden;

    @media only screen and (max-width: 600px) {
      width: 7rem;
      justify-self: flex-start;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }
}

.full-height {
  height: calc(100vh - 120px);
}

.carousel-home {
  margin: 0 -15px;
  width: auto;
  margin-top: -40px;
}

.carousel-show {
  position: relative;

  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: '';
    padding-bottom: 40%;
    display: block;
  }
}

.custom-data-table {
  min-height: 350px;
}

//Buttons

.btn {
  border-radius: $border-radius-lg;
  transition: 0.1s all ease-in-out;

  &:hover,
  &:focus {
    border-color: transparent;
    outline: none;
    box-shadow: none;
  }
}

.btn-success,
.btn-outline-success {
  &:hover,
  &:focus, &.active {
    color: white !important;
  }
}

.btn-primary {
  background-color: $primary;
  color: white;
  transition: 0.1s all ease-in-out;
  border-color: transparent;

  &:hover,
  &:focus {
    background-color: $primary-light;
    border-color: transparent;
    outline: none;
    box-shadow: none;
  }

  &.cancel {
    color: white;
    background-color: #bdbdbd !important;
    transition: 0.1s all ease-in-out;

    &:hover,
    &:focus {
      color: white;
      background-color: $gray-600;
      border-color: transparent;
      outline: none;
      box-shadow: none;
    }
  }
}

.btn-output {
  background-color: $danger;
  color: white;
  transition: 0.1s all ease-in-out;
  border-color: transparent;

  &:hover,
  &:focus {
    background-color: lighten($danger, 10%);
    border-color: transparent;
    color: white;
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    color: white;
  }
}

.btn-success {
  background-color: $success;
  color: white;
  transition: 0.1s all ease-in-out;
  border-color: transparent;

  &:hover,
  &:focus {
    background-color: lighten($success, 10%);
    border-color: transparent;
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    color: white;
  }
}

#branch-office-user {
  width: 100%;
  height: 100%;
  align-self: center;
}

.branch-office-btn {
  padding: 0;
  width: 100%;
  min-width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  color: white !important;
  transition: 0.1s all ease-in-out;
  border-color: transparent;
  border-radius: 24px;
  border-width: 0px;
  border-style: solid;
  height: 2rem;
  color: white !important;
  margin: 0 1.5rem;

  &:hover,
  &:focus {
    background-color: $primary-light;
    border-color: transparent;
    border-radius: 24px;
    border-width: 0px;
    border-style: solid;
    outline: none;
    box-shadow: none;
  }

  .nav-link {
    color: white !important;
  }
}

.basic-nav-dropdown-link {
  width: 100%;
  height: 100%;
}

.user-nav-btn {
  display: flex;
  padding: 0 1rem;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  transition: 0.1s all ease-in-out;
  border-color: transparent;
  border-radius: 24px;
  border-width: 1px;
  border-style: solid;
  height: 2rem;

  .nav-link {
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white !important;
  }

  &:hover,
  &:focus {
    background-color: lighten($primary, 10%);
    border-color: transparent;
    border-radius: 24px;
    border-width: 1px;
    border-style: solid;
    outline: none;
    box-shadow: none;
  }
}

.jxBfIW {
  display: flex;
  justify-content: space-around;
}

.btn-link {
  color: $primary;
  background: transparent;
  border-color: transparent;
  transition: 0.1s all ease-in-out;

  &:hover {
    color: $primary-light;
    text-decoration: underline;
  }
}

.btn-link-no-hover-effects {
  color: $primary;
  background: transparent;
  border-color: transparent;
  transition: 0.1s all ease-in-out;
  text-align: left;

  &:hover,
  &:active,
  &:focus {
    color: $primary-light;
    text-decoration: underline;
    background-color: transparent;
  }
}

.btn-circle {
  border-radius: 100%;
  padding: 0.5rem;
  transition: 0.1s all ease-in-out;

  &.table-actions-btn {
    margin-right: 0.5rem;
  }
}

.btn-circle.dropdown-toggle::after {
  display: none !important;
}

.dropdown .btn-circle.btn-outline-success:focus {
  color: $success !important;
  background-color: white !important;
}

.btn-circle.btn-outline-success {
  color: $success;

  &:hover {
    color: white !important;
  }

  &:focus {
    color: $success !important;
    border-color: $success !important;
  }
}

.btn-outline-info {
  color: $info;

  &:hover {
    color: white;
  }
}

.btn-outline-warning {
  color: $warning;

  &:hover {
    color: white;
  }
}

.btn-outline-secondary {
  color: $secondary;

  &:hover, &.active {
    color: white !important;
  }
}

.btn-outline-danger {
  color: $danger;

  &:hover {
    color: white;
  }
}

.fc-button-group {
  .btn,
  .fc-button-primary {
    background-color: $primary;
    border-radius: $border-radius-lg;
    color: white;
    transition: 0.1s all ease-in-out;
    border-color: transparent;
    border-width: 2px;
    padding: 5px 10px;
    height: 2rem;

    &:hover,
    &:focus {
      background-color: $primary-light;
      border-color: transparent;
      outline: none;
      box-shadow: none;
    }

    &:active,
    &.fc-button-active {
      background-color: white;
      color: $primary;
      border-color: $primary;
      outline: none;
      box-shadow: none;
      font-weight: 700;
    }
  }
}

//Forms&Inputs

pre {
  font-family: 'Roboto', sans-serif;
  font-size: 0.75rem;
  line-height: 1.375;
}

.form-group {
  transition: 0.1s all ease-in-out;

  .form-label {
    transition: 0.1s all ease-in-out;
    font-weight: 700;
  }

  textarea {
    min-height: 5rem;

    &.form-control {
      padding: 1rem;
      cursor: text !important;
    }
  }

  .form-control {
    transition: 0.1s all ease-in-out;
    border-radius: $border-radius-lg;
    padding: 5px 10px;
    height: 36px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      border: 1px solid $primary;
      outline: none !important;
      box-shadow: none !important;
    }

    &:focus {
      border: 2px solid $primary;
      outline: none !important;
      box-shadow: none !important;
    }

    &:disabled {
      background-color: hsl(0, 0%, 95%);

      &:hover {
        border: 1px solid #ced4da;
        cursor: default;
      }
    }
  }
}

.css-192e43e-control,
.css-gv9jl3-control {
  border: 1px solid #ced4da !important;
  &:hover {
    border: 1px solid #ced4da !important;
    cursor: default;
  }
}

.react-datepicker-time__header {
  visibility: hidden;
  padding-top: 0;

  &:after {
    content: 'Hora';
    visibility: visible;
    display: block;
    margin: none;
  }
}

// Datatable Filters

.datatable-custom-filters-container {
  display: flex;
  width: 100%;
  padding: 0 15px;

  & > * {
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

//Others

.img-align-center {
  margin: 0px auto;
  text-align: center;

  img {
    width: 50%;
  }
}

.checkbox-align-center {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 0.8rem;
}

.modal-90w {
  width: 90%;
  max-width: none !important;

  .modal-content {
    overflow-y: auto;
    max-height: 90vh;
    min-height: 90vh;
  }
}

.modal-new-form-btn {
  z-index: 1;
  position: absolute;
  top: -20px;
}

@media (min-width: 768px) {
  .calendar-filters {
    position: absolute;
    top: 96px;
  }

  .hidden-title {
    visibility: hidden;
  }

  .modal-save-new-form-btn {
    top: 13px;
    left: -13px;
  }

  .modal-new-form {
    z-index: 1;
    position: absolute;
    top: -17px;
  }

  .modal-header-padding {
    padding: 1rem 2rem;
  }

  .modal-body-padding {
    padding: 1rem 2rem;

    &.mh-640 {
      min-height: 640px;
    }
  }

  // Mechanic Cards Index Columns

  .m-title-index-1 {
    margin-left: 1.4rem;
  }

  .m-title-index-2 {
    margin-left: 2.8rem;
  }

  .mechanic-card-index-0 {
    border-right-color: gainsboro;
    border-right-style: ridge;
    border-right-width: thin;
    height: 93%;
    margin-right: -0.3rem;
    padding-right: 2.1rem;
    display: block;
  }

  .mechanic-card-index-1 {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }

  .mechanic-card-index-2 {
    border-left-color: gainsboro;
    border-left-style: ridge;
    border-left-width: thin;
    height: 93%;
    margin-left: -0.3rem;
    padding-left: 2.1rem;
  }
}

@media (max-width: 600px) {
  .calendar-filters {
    position: relative;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .fc-header-toolbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem !important;

    .fc-toolbar-chunk {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      .fc-button-group {
        position: relative;

        .fc-dayGridMonth-button,
        .fc-timeGridWeek-button,
        .fc-timeGridDay-button,
        .fc-prev-button,
        .fc-next-button {
          bottom: unset;
          width: 100%;
        }
      }

      .fc-today-button {
        position: relative;
        background: white;
        color: $gray-500;
        border: 2px solid $gray-500;
        transition: all 0.4s ease-in-out;
        padding: 5px 15px;

        &:disabled {
          background-color: $primary;
          opacity: 1;
          border: 0;
          color: white;
          font-weight: 700;
          border: 2px solid $primary;
        }
      }

      .fc-toolbar-title {
        width: 200px;
        font-size: 1rem;
      }
    }
  }

  .mobile-mt-3 {
    margin-top: 1rem !important;
  }

  .change-modal-order,
  .dropzone-order,
  .back-confirm-project-order {
    display: flex;
    flex-direction: column;
  }

  .change-modal-order .nn-calendar-event-tab {
    order: 1;

    div:nth-child(4) {
      order: 4;
    }
  }

  .change-modal-order .nn-client-tab {
    order: 3;

    div:nth-child(5) {
      order: 5;
    }
  }

  .change-modal-order .nn-vehicle-tab {
    order: 4;

    div:nth-child(5) {
      order: 5;
    }

    div:nth-child(6) {
      order: 6;
    }
  }

  .change-modal-order .nn-calendar-event-form {
    order: 2;
  }

  .dropzone-order .dropzone-images-btn {
    order: 1;
  }

  .dropzone-order .dropzone-images-error-message {
    order: 2;
  }

  .dropzone-order .dropzone-images-text {
    order: 3;
  }

  .back-confirm-project-order .back-project-btn {
    order: 1;
  }

  .back-confirm-project-order .confirm-project-btn {
    order: 2;
  }
}

.mb-6 {
  margin-bottom: 4.9rem !important;
}

.mb-7 {
  margin-bottom: 6.9rem !important;
}

.mb-8 {
  margin-bottom: 8.9rem !important;
}

.mb-9 {
  margin-bottom: 12.9rem !important;
}

//Landing

.contact-btn {
  width: 20rem;

  @media only screen and (max-width: 600px) {
    width: 15rem;
  }
}

.img-square-box {
  aspect-ratio: 1.5 / 1;
  border-radius: 25px;
  object-fit: cover;
  width: 100%;
}

.sections-padding {
  padding: 0 5rem;

  @media only screen and (max-width: 600px) {
    padding: 0 1rem;
  }
}

.slogan-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  h2,
  p {
    text-align: center;
  }

  button {
    width: 20rem;
  }

  .landing-plans-points {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    padding: 0;

    @media only screen and (max-width: 768px) {
      justify-content: space-between;
      padding: 0;
      flex-direction: column;
    }

    li {
      flex: 1 0 31%;
      margin: 10px 10px 30px 10px;
      max-width: 170px;
      font-size: 14px;
      color: $dark;
    }
  }
}

.landing-cards-row {
  display: flex;
  justify-content: space-evenly;

  .landing-cards-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .landing-card {
      height: auto;
      width: 20rem;
      border-color: transparent;

      @media only screen and (max-width: 600px) {
        margin-bottom: 3rem;
      }

      .card-image-container {
        height: 10rem;
        width: 100%;
        display: flex;

        .card-image {
          object-fit: contain;
          width: 100%;
        }
      }
    }

    .card-title {
      text-align: center;
      font-weight: 700;
      font-size: 0.8rem !important;
    }

    .card-description {
      text-align: center;
    }
  }
}

.landing-image-section {
  display: flex;
  flex-direction: row;
  padding: 0 5rem;
  justify-content: center;
  align-items: center;

  .section-image-container {
    display: flex;
    overflow: hidden;
    height: 25rem;
    width: auto;
    justify-content: center;
    align-items: center;

    .section-image {
      object-fit: contain;
      height: 100%;

      @media only screen and (max-width: 600px) {
        width: 100%;
        height: auto;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 0;

    &.reorder {
      .section-image-container {
        order: 1;
      }

      .text-section {
        order: 2;
      }
    }
  }
}

//Info Box

.info-box {
  .info {
    span {
      color: $gray-500;
      width: 45%;
      display: inline-block;
    }

    color: $dark;
    margin-bottom: 7px;
  }
}

.project-works-info-box {
  .info {
    span {
      font-weight: bolder;
    }

    p {
      color: $dark;
      margin: 5px 0;
    }
  }
}

// Cards Style

.custom-card-style {
  .body-row {
    padding-right: 0.4rem;
  }

  .body-info {
    span {
      font-weight: bold;
    }

    div {
      margin-bottom: 1rem;
    }
  }

  .card-predefined-size {
    height: 13rem;

    .body-row {
      height: 7.6rem;
    }
  }

  .tag-style {
    border-radius: 0 calc(0.75rem - 3px) 0 !important;
    margin-bottom: 3.5rem;
    margin-top: -1.3rem;
  }

  .footer-style {
    background-color: $white;
    border-top: 0;
    font-size: $h2-font-size;
    font-weight: bold;
  }
}

//Toasts

.Toastify__toast-container--top-right {
  top: 4rem;
}

//Project Show

.project-section {
  background-color: #f7f7f7;
  padding: 1rem;

  &.public {
    border: 7.5px solid white;
  }

  &.project-show-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .nav-info-section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    p {
      margin: 0;
      letter-spacing: 0.03em;
    }
  }

  p {
    letter-spacing: 0.03em;

    .data-title {
      margin-right: 3px;
    }
  }

  .project-info-division {
    border-right-color: gainsboro;
    border-right-width: thin;
    border-right-style: ridge;
  }
}

.section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.add-payment-btn {
  max-width: 10rem;
}

.project-show-menu-btn,
.wide-view-btn {
  .active-btn,
  .active {
    &.btn-link {
      background-color: $primary;
      border: 1px solid $primary;
      color: white;
      font-weight: 700;
      text-decoration: none;

      &:hover,
      &:focus {
        color: white;
      }
    }
  }

  .inactive-btn,
  .inactive,
  .btn-link {
    background-color: white;
    border: 1px solid #efefef;
    color: $gray-800;
    font-weight: 700;

    &:hover,
    &:focus {
      border: 1px solid $primary;
      color: $primary;
      text-decoration: none;
    }

    &:active {
      background-color: $primary;
      color: white;
      border: 1px solid $primary;
    }
  }
}

.project-show-nav-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 8rem;

  &:hover {
    text-decoration: none;
  }
}

.hidden {
  display: none !important;
}

.m-t-30 {
  margin-top: 30px;
}

.clickable {
  cursor: pointer;
}

//Project Show OS

.gallery-small-box {
  width: auto;
  height: 10rem;
  overflow: hidden;
  padding: 1rem;
  margin-bottom: 1rem;
  transition: all 0.1s linear;

  .gallery-small-title {
    font-weight: 700;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: all 0.1s linear;

    &:hover {
      opacity: 0.8;
      box-shadow: 0px 2px 4px rgba(25, 1, 52, 0.1);
    }
  }
}

.work-header {
  background-color: #e0e0e0;
  border-radius: 24px 24px 0 0;
  display: flex;
  align-items: center;
  min-height: 7rem;
  padding-left: 1rem;

  .form-control {
    margin-bottom: 0;
  }
}

.work-dropdown {
  background-color: transparent;
  color: $dark;
  border: 1px solid transparent;
  border-radius: 50%;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  align-self: flex-start;

  &:hover {
    background-color: $primary;
    color: white;
  }

  &:focus,
  &:active,
  &.work-dropdown[aria-expanded='true'] {
    background-color: $primary !important;
    color: white;
    outline: none;
    box-shadow: none;
  }
}

.work-details-container {
  border-radius: 0 0 24px 24px;
  height: auto;
}

.work-detail-col {
  padding: 0 10px 0 10px;
}

.work-detail-row {
  margin-left: 0;
}

.work-box {
  height: auto;
  background: white;
  border-radius: 24px;
  box-shadow: 0px 2px 4px rgba(25, 1, 52, 0.1);
  margin-bottom: 2rem;
}

.trash-container {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(25, 1, 52, 0.1);
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.1s linear;

  .delete-item {
    width: 1rem;
    height: 1rem;
  }

  &:hover {
    color: $danger;
    box-shadow: 0px 2px 10px rgba(151, 0, 20, 0.438);
    border: 2px solid $danger;
  }
}

.custom-trash {
  position: relative;
  top: -2rem;
  right: -0.7rem;
}

.custom-external-link {
  padding: 0;
  margin-bottom: 0.1rem;
  margin-left: 0.2rem;
}

.item-background {
  background-color: $light;
  padding: 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  transition: all ease-in-out 0.2s;

  &.free-item {
    background-color: #fff9f0;
    border: 2px solid #ffeac7;
    transition: all ease-in-out 0.2s;
  }
}

//ImageGallery

.image-gallery-slides {
  overflow: hidden;
  height: 18rem;

  .image-gallery-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.added-images {
  font-weight: 700;
}

.no-images {
  font-weight: 500;
}

.dropzone-box {
  padding: 1rem;
  background-color: $light;
  border-radius: 10px;
  margin-bottom: 1rem;

  .box-img {
    .content {
      img {
        object-fit: cover;
        width: 100%;
        height: auto;
      }

      .hover-dropzone {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        z-index: 99;
        border-radius: 0;
      }
    }
  }
}

.image-gallery-slide {
  height: 100%;
  width: 100%;
  display: flex;
}

.image-gallery-image {
  height: 100%;
  width: auto;
  object-fit: contain !important;
  max-height: unset !important;
}

.image-gallery-thumbnails-container {
  display: flex;
  align-items: center;
}

.image-gallery-thumbnail {
  overflow: hidden;
  width: 100px;
  height: 100px;
  margin: 1rem;
}

.image-gallery-thumbnail-inner {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: flex;

  img {
    object-fit: fill;
    width: 100%;
    height: auto;
  }
}

.image-delete {
  border-radius: 0;
}

//Company Profile

.weekday-selector-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.weekday-selector {
  display: flex;
  align-items: center;

  .custom-control-input {
    cursor: pointer;
    position: relative;
    display: flex;
    align-self: center;
  }

  .custom-control-label {
    display: flex;
    align-self: center;
    cursor: pointer;
  }
}

// Dropdown

.dropdown-no-arrow::after {
  display: none !important;
}

// Table

.border-top-borderless-table {
  border-top-color: gainsboro;
  border-top-width: thin;
  border-top-style: ridge;

  .header-border-bottom {
    border-bottom-color: gainsboro;
    border-bottom-width: thin;
    border-bottom-style: ridge;
  }
}

.no-margin {
  margin: 0px !important;
}

tr.col-vertical-center {
  td {
    vertical-align: middle !important;
  }
}

tr.col-not-created-product {
  background-color: blanchedAlmond !important;

  td:nth-child(3) {
    color: red;
  }
}

//Checklists

.checklist-section-container {
  background-color: $light;
  padding: 2rem 1rem;
  border-radius: 24px;
}

.checklist-point-container {
  background: white;
  padding: 1.5rem 1rem 1rem 1rem;
  border-radius: 24px;
}

.checklist-points-group-container {
  padding: 1rem;
  margin: 0 -2rem;
  background: red;

  .checklist-points-group {
    padding: 0 3rem;
  }
}

//Shows

.show-info-container {
  padding: 2rem 3rem;
  background-color: $light;
  border-radius: 24px;
  display: flex;
  flex-direction: column;

  .show-info-small-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .show-info-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      .address-line {
        display: flex;
        flex-wrap: wrap;
        max-width: 60%;
      }
    }
  }
}

// FullCalendar

.fc-event-main-frame {
  .fc-event-time {
    width: 100%;
    background: rgba(34, 34, 34, 0.3);

    .event-header {
      padding: 2px;
      font-weight: 600;
    }
  }
}

.no-margin {
  margin: 0px !important;
}

.vertical-center {
  vertical-align: middle !important;
}

#MobileMenuDivWrapper {
  height: 80vh;
  max-height: 80vh;
  width: 100vw;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

// Reports

.report-big-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 20rem;
  min-height: 120px;
  font-size: 1rem;
  font-weight: 700;
  transition: all ease-in-out 0.1s;

  &:hover {
    font-size: 1.1rem;

    .report-icon {
      width: 33px;
      height: 33px;
    }
  }

  p {
    margin: 0;
  }

  .report-icon {
    width: 30px;
    height: 30px;
    transition: all ease-in-out 0.1s;
  }
}

.textarea-custom {
  .one-line {
    min-height: 2rem;
    padding: 9px 10px !important;
    resize: none;

    &:focus {
      padding: 8px 10px !important;
    }
  }

  .two-lines {
    min-height: 4rem;
    padding: 14px !important;
    resize: none;
  }
}

.project-config-btn {
  z-index: 1;
  position: absolute;
  top: -8px;
}

.nn-purchase-management {
  .rdt_TableCell {
    justify-content: flex-start;
  }
}

.movement-detail-div > .table-responsive {
  max-height: 304px;
}

.no-search-data-table > div > header {
  display: none;
}

.checkbox-associate-purchase {
  .custom-control-input,
  .custom-control-label {
    cursor: pointer;
  }
}

//Plans

.plans-bottom-text {
  color: #6d6d6d;
  font-size: 16px;
  line-height: 140%;
  font-weight: 400;
  text-align: center;
}

.plans-banner-container {
  overflow: hidden;
  max-height: 500px;
  padding: 0;
  min-height: 400px;
  margin-top: -40px;

  @media only screen and (max-width: 768px) {
    justify-content: space-between;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.plans-title {
  font-size: 18px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
}

.plans-contact-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 768px) {
    justify-content: space-between;
  }

  p {
    color: white;
    font-weight: 700;
    margin: 0;
    font-size: 14px;
  }

  .plans-contact {
    min-width: 120px;
    margin-left: 40px;
    background-color: white;
    color: $primary;
    font-weight: 700;
    font-size: 14px;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }
}

.plan-cards-container {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin-top: 3rem;

  @media only screen and (max-width: 768px) {
    flex-flow: column nowrap;
    justify-content: center;
    padding: 0 20px;
    margin-top: 0;
  }
}

.plan-card {
  display: flex;
  flex-direction: column;
  flex: 1 0 29%;
  margin: 5px 30px 70px 30px;
  background-color: white;
  box-shadow: 0px 0px 50px rgba(102, 140, 181, 0.5);
  border-radius: 16px;
  padding: 60px 40px;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    margin: 60px 0px;
  }

  .highlight-header {
    visibility: hidden;
    height: 0px;
    background-color: $secondary;
    width: auto;
    padding: 30px 20px;
    margin: -60px -40px 40px -40px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: white;
      font-size: 20px;
      font-weight: 700;
      margin: 0;
    }
  }

  .price-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .price {
      font-size: 42px;
      color: $dark;
      font-weight: 700;
    }

    .price-text {
      font-size: 18px;
      color: $dark;
      font-weight: 700;
    }
  }

  .price-division {
    border-bottom: 2px dotted $primary;
    margin-bottom: 30px;
    height: 10px;
  }

  .plan-name-container {
    .plan-name {
      font-size: 24px;
      font-weight: 700;
      color: $dark;
      margin-bottom: 20px;
      padding-left: 10px;
    }
  }

  .plan-list-container {
    .plan-list {
      padding-left: 20px;

      ul {
        padding-inline-start: 10px;

        li {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }

  .select-btn-container {
    display: flex;
    justify-content: center;
    margin-top: auto;

    .plans-selection-btn {
      background-color: white;
      color: $primary;
      font-weight: 700;
      font-size: 16px;
      padding: 10px 30px;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
      margin-top: 50px;

      &:hover,
      &:focus {
        box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.2);
        color: $primary-light;
      }
    }
  }

  &.plan-highlight {
    box-shadow: 0px 0px 50px rgba(242, 153, 74, 0.35);
    top: -50px;

    .highlight-header {
      visibility: visible;
      height: auto;
    }

    .price,
    .price-text,
    .plan-name {
      color: $secondary;
    }

    .price-division {
      border-color: $secondary;
    }

    .plans-selection-btn {
      background-color: $secondary;
      color: white;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);

      &:hover,
      &:focus {
        box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.2);
        color: white;
        background-color: #ffba49;
      }
    }
  }
}

.project-work-hour-report-label {
  position: absolute;
  top: -20px;
  left: 25px;
}

#login {
  min-height: 100vh;
}

//LANDING//
#home {
  @include mobile {
    margin-right: -15px;
  }
  .btn-primary-yellow__landing {
    width: 60%;
  }
}

.home-landing {
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: white;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-container {
  padding: 0px;
}

.home-header-container {
  height: 105vh;
  background-image: url(/assets/images/banner-gestioncar.webp);
  line-height: 65px;
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    font-size: 60px;
    color: white;
  }
  p {
    color: white;
    line-height: 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }
  @include mobile {
    h1 {
      font-size: 40px;
      margin-top: 0px;
    }

    p {
      color: white;
      line-height: 25px;
      font-size: 18px;
    }
  }
}

.home-header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 12%;
  padding: 0px 10%;
  @include mobile {
    margin-top: 18%;
  }
}

.content-body {
  @include desktop {
    margin-left: 250px;
    margin-right: 20px;
  }
}

.container-fluid {
  padding: 0px !important;
}

#benefits {
  margin: 170px 80px 80px 80px;

  h3 {
    font-size: 35px;
    font-weight: 700;
  }
  p {
    font-size: 16px;
    font-weight: 400;
  }
  .btn-link-arrow {
    float: right;
  }
  .row-section-benefits {
    margin: 150px 0px 150px 0px;
    align-items: center;
  }

  @include mobile {
    margin: 80px 40px 80px 40px;

    .row-section-benefits {
      margin: 80px 0px 80px 0px;
    }
    h3 {
      font-size: 30px;
      margin-bottom: 5px !important;
      margin-top: 25px;
    }
    p {
      font-size: 20px;
    }
  }
}

.image-right {
  padding-right: 15px;
  @include mobile {
    padding: 0px;
    text-align: center;
  }
}

.image-left {
  padding-left: 15px;
  @include mobile {
    padding: 0px;
    text-align: center;
  }
}

.text-sm-center {
  padding-left: 15px;
  @include mobile {
    text-align: center;
  }
}

#scheduling {
  margin: 170px 80px 120px 80px;

  h3 {
    font-size: 35px;
    font-weight: 700;
    color: black;
  }

  .bg-gray-scheduling {
    display: flex;
    align-items: center;
    padding: 25px 45px;
    background-color: #f0f0f0;
    border-radius: 60px;
    margin: auto;
    @include mobile {
      width: 100%;
      padding: 25px 15px;
    }
  }

  @include mobile {
    margin: 80px 40px 80px 40px;

    h3 {
      font-size: 30px;
      margin-bottom: 5px !important;
      margin-top: 25px;
    }
  }
}

#funtions {
  margin: 170px 80px 80px 80px;

  img {
    width: 50%;
  }
  p {
    font-size: 16px;
  }
  h3 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 30px !important;
  }
  @include mobile {
    margin: 80px 40px 80px 40px;

    p {
      font-size: 13px;
    }

    h3 {
      font-size: 30px;
      margin-top: 25px;
    }
  }
}

.button-font {
  font-size: 16px !important;
  font-weight: 700;

  @include mobile {
    font-size: 20px !important;
  }
}

.button-font-navbar {
  font-size: 14px !important;
  font-weight: 500;
}

#video-publicity {
  margin: 150px 0px 150px 0px;

  @include mobile {
    margin: 80px 10px 80px 10px;
  }

  h3 {
    font-size: 35px;
    font-weight: 700;
  }
}

.button-center {
  display: flex !important;
  justify-content: center !important;
}

.card-success {
  width: 80%;
  margin: auto;
  @include mobile {
    width: 100%;
    .card-margin {
      margin-bottom: 30px !important;
    }
  }
}

.card-margin {
  @include mobile {
    width: 100%;
    margin-bottom: 30px !important;
  }
}

#success-stories {
  margin: 150px 0px 150px 0px;
  padding-top: 20px;

  @include mobile {
    padding: 50px 20px 50px 20px;
  }

  .blue-bg {
    background-color: #4a7b95;
    margin: auto;
    padding: 15px 20px;
    border-radius: 30px;
  }

  h3 {
    font-size: 35px;
    font-weight: 700;
    color: white;
    line-height: 103%;
  }

  h4 {
    padding-top: 15px;
    margin: auto;
    font-weight: 700;
    font-size: 30px;
    line-height: 103%;
    color: #ffad26;
  }

  .btn-primary-blue__landing {
    width: 25%;
  }

  .card {
    box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05) !important;
    border-radius: 25px !important;
    border: 0px;
    .card-title {
      font-weight: 700;
      font-size: 20px;
    }
    .card-text {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 5px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    a {
      display: flex;
      justify-content: flex-end;
      float: right;
    }
    @include mobile {
      height: 300px;
    }

    @include mobilesm {
      height: 95%;
    }
  }
}

#Faqs {
  margin: 150px 0px 150px 0px;
  @include mobile {
    margin: 50px 20px 50px 20px;
  }
  .card-header {
    background-color: rgba(95, 154, 184, 0.1);
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid rgba(95, 154, 184, 0.5);

    &:hover {
      background-color: rgba(95, 154, 184, 0.3);
      cursor: pointer;
    }
  }
  .card {
    border-color: rgba(95, 154, 184, 0.2);
  }

  .card-body {
    font-size: 14px;
  }

  h3 {
    font-size: 35px;
    font-weight: 700;
  }
}

.white-text {
  color: white;
}

#pricing {
  margin-bottom: 100px;
  background: linear-gradient(180deg, #5e99b8 70%, #ffffff 70%);
  padding: 25px 20px 20px 20px;
  width: 100%;
  justify-content: center;
  margin-left: 0px;
  margin-right: 0px;

  @include mobile {
    background: linear-gradient(180deg, #5e99b8 100%, #ffffff 100%);

    p {
      font-size: 18px;
    }
    h4 {
      font-size: 22px;
    }

    .w-85-mobile {
      width: 85% !important;
    }
  }

  h3 {
    font-size: 35px;
    font-weight: 700;
    color: white;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
  }

  h4 {
    font-weight: 700;
    font-size: 20px;
    color: white;
  }

  .text-pricing {
    font-size: 35px;
    font-weight: 700;
    text-align: center;
  }

  .card {
    box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05) !important;
    border-radius: 25px !important;
    border: 0px;
    li {
      font-size: 16px;
    }
    .card-title {
      font-weight: 700;
      font-size: 20px;
    }
    .card-text {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 5px;
    }
    button,
    .btn-primary-yellow__landing {
      width: 70%;
      display: flex;
      justify-content: center;
      margin: auto;
      margin-top: 20px !important;
    }
  }
}

.sm-margin-bottom-15 {
  @include mobile {
    margin-bottom: 15px !important;
  }
}

.recomended {
  background: #ffad26;
  text-align: center;
  padding: 12px;
  border-radius: 25px 25px 0px 0px;
}

.col-pricing-principal {
  flex: 0 0 38%;
  max-width: 38%;

  @media (max-width: 931px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-pricing {
  flex: 0 0 31%;
  max-width: 31%;

  @media (max-width: 931px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.img-tool1 {
  width: 60%;
  display: flex;
  margin: auto;
  margin-bottom: -80px !important;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
  @include mobile {
    width: 90%;
    margin-bottom: -35px;
  }
}

#ClosePublicity {
  min-height: 35vh;
  max-width: 2000px;
  margin: 200px auto 100px auto;

  @include mobile {
    margin: 50px;

    h2 {
      font-size: 27px;
    }

    a {
      width: 100%;
    }
  }
  .btn-primary-blue-outline__landing {
    float: right;
  }

  .img__closepublicity {
    position: absolute;
    left: 135px !important;
    top: -40% !important;

    @include mobile {
      position: inherit !important;
      width: 50%;
    }
  }

  h2 {
    font-size: 30px;
    color: white;
  }

  .font-left__closepublicity {
    left: 160px;

    @include mobile {
      left: 0px;
      padding: 0px 35px;
    }
  }
  .bg-blue-close {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #4a7b95 0%, #5e99b8 100%);
    border-radius: 100px 0px;
    width: 75%;
    min-height: 200px;
    margin: auto;

    @include mobile {
      min-height: 300px;
      width: 100%;
      margin: auto;
      padding-bottom: 45px;
    }
  }
}

//BUTTONS LANDING

.btn-link-arrow {
  color: $primary;
  background: transparent;
  border-color: transparent;
  transition: 0.1s all ease-in-out;

  &:hover {
    color: $primary-light;
    text-decoration: underline;
  }
}

.btn-primary-blue__landing {
  background-color: $primary;
  color: white !important;
  transition: 0.1s all ease-in-out;
  border-color: transparent;

  &:hover,
  &:focus {
    background-color: #4a7b95;
    color: white !important;
    border-color: transparent;
    outline: none;
    box-shadow: none;
  }
}

.btn-primary-yellow__landing {
  background-color: #ffad26;
  color: white;
  transition: 0.1s all ease-in-out;
  border-color: transparent;

  &:hover,
  &:focus {
    background-color: #e19117 !important;
    color: white;
    border-color: transparent;
    outline: none;
    box-shadow: none;
  }
}

.btn-primary-blue-outline__landing {
  background-color: white;
  border: 1px $primary solid;
  color: $primary !important;
  transition: 0.1s all ease-in-out;

  &:hover,
  &:focus {
    background-color: #cfe0ea;
    border: 1px $primary solid;
    color: $primary !important;
    box-shadow: none;
  }
}

.img-banner__login {
  background-image: url(/assets/images/banner-login.webp);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

#steps {
  margin-top: 200px;
  margin-bottom: 200px;
  scroll-snap-type: y proximity;
  scroll-snap-align: start;

  img {
    margin-top: -21%;
    margin-left: -62%;
    width: 60%;
    position: absolute;
    z-index: 10000;
  }
  .card {
    padding-top: 80px;
    padding-bottom: 20px;
    border: 0px;
    border-radius: 20px;
    box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05);

    @include mobile {
      height: 350px;
    }

    @include mobilesm {
      height: 100%;
    }
  }
  .card-body {
    height: 150px;
    text-align: left;

    h2 {
      color: $primary;
    }

    p {
      font-size: 16px;
    }
  }

  h3 {
    font-size: 35px;
    font-weight: 700;
  }
}

.bg-blue__steps {
  background: #cfe0ea;
  width: 90%;
  margin: auto !important;
  border-radius: 30px;
  padding: 40px 30px;
}

.w-30 {
  width: 30%;
  margin: auto;
}

.w-70 {
  width: 70%;
  margin: auto;
}

.w-80 {
  width: 80%;
  margin: auto;
}

.w-90 {
  width: 90%;
  margin: auto;
}

.width-85 {
  width: 85%;
}

.w-100-mobile {
  @include mobile {
    width: 100% !important;
  }
}

.video-react .video-react-big-play-button {
  top: 47%;
  left: 43%;

  @include mobilesm {
    top: 45%;
    left: 39%;
  }
}

.video-react.video-react-fluid,
.video-react.video-react-16-9,
.video-react.video-react-4-3 {
  width: 60%;
  max-width: 100%;
  height: 0;
  margin: auto;
  border-radius: 30px;
  padding: 20% !important;

  @include mobile {
    width: 100%;
    padding-top: 60% !important;
  }
}

.video-margin-mobile {
  @include mobile {
    margin: 20px 0px 20px 0px;
  }
}

.video-react .video-react-control-bar {
  border-radius: 0px 0px 30px 30px;
  padding: 0px 10px 0px 10px;
}

.order-sm-1 {
  order: 2;
  @include mobile {
    order: 1;
  }
}

.order-sm-2 {
  order: 1;
  @include mobile {
    order: 2;
  }
}

.order-sm-3 {
  order: 3;
  @include mobile {
    order: 3;
  }
}

.m-sm-0 {
  @include mobile {
    margin: 0px;
  }
}

.navbar-gestioncar {
  scroll-behavior: smooth;

  .btn {
    @include mobile {
      margin-bottom: 15px;
    }
  }

  ul {
    text-decoration: none;
    list-style: none;
    display: flex;
    margin: auto;

    @include mobile {
      display: block !important;
      text-align: center;
    }
  }

  li {
    margin: 10px;
    padding: 5px;
    text-decoration: none;
    list-style: none;
    font-size: 15px;
    @include mobile {
      font-size: 14px;
    }
  }
}

.navbar-nav {
  @include mobile {
    margin: 20px;
  }
}

.float-button {
  z-index: 10000000;
  top: 80vh;
  right: 15px;
  box-shadow: 0px 5.14557px 15.4367px rgba(0, 0, 0, 0.25);
  background: #5cc689;
  padding: 9px 0px 9px 10px;
  width: 60px;
  height: auto;
  border-radius: 50px;
  border: 4px solid white;
  position: fixed;

  &:hover {
    background-color: #65dc97;
    cursor: pointer;
  }
}

.max-width-2000 {
  @include large-screens {
    max-width: 2000px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  @include xl-screens {
    max-width: 2000px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.max-width-2000-xxl {
  @include xl-screens {
    max-width: 2000px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.d-xxl-none {
  @include xl-screens {
    display: none !important;
  }
}

.faqs-xl {
  @include xl-screens {
    justify-content: center;
  }
}

#modal-success {
  .modal-body {
    font-size: 16px;
  }
  .modal-footer {
    border: 0px;
  }

  h4 {
    font-size: 25px;
    font-weight: 900;
  }
}

.container-fluid {
  #landing-container {
    overflow-x: hidden !important;
  }

  #login {
    overflow-x: hidden !important;
  }

  #contact {
    width: 100%;
    overflow-x: hidden !important;
  }

  h2 {
    font-size: 1.125rem;
  }
}

.inter-space {
  padding-top: 6rem;
  margin-bottom: 6rem !important;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.blue-text {
  color: $blue !important;
}

.card-pricing-svg {
  width: 25px;
  background: #5cc689;
  fill: white;
  padding: 5px;
  border-radius: 100px;
}

.mt-2rem {
  margin-top: 2rem;
}

.pb-1 {
  padding-bottom: 1px !important;
}

.nav-tabs .nav-link.active {
  background-color: $blue !important;
}

.equal-width-tabs .nav-item {
  flex: 1;
}

.equal-width-tabs .nav-link {
  text-align: center;
}

.dropdown-image .dropdown-toggle::after {
  margin-top: 10px;
}

.dropdown-image a {
  display: flex;
}

.inverse .week-day-input.undefined{
  margin: 0 !important;
}

.work-detail-col .work-detail-row .work-detail-col .form-group {
  margin-bottom: 0 !important;
}

.subcategory-detail label {
  white-space: nowrap;
}

.table-responsive td.id-pago {
  word-break: break-word;
  white-space: normal;
  max-width: 225px; }

@mixin customStyles($overflow: hidden) {
  min-width: 180px;
  min-height: 400px;
  height: 62vh;
  background-color: #ececec;
  border-radius: 5px;
  overflow: $overflow;

  @media (max-width: 576px) {
    min-height: 160px;
    height: 40vh;
  }
}

.project-columns {
  @include customStyles(auto);
  width: 200px;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  &:hover {
    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: #fff;
      width: 16px;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &::-webkit-scrollbar-track:hover {
      background-color: #f4f4f4;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 5px solid #fff;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #a0a0a5;
      border: 4px solid #f4f4f4;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
  @media (max-width: 576px) {
    overflow-y: auto;
  }
}

.project-columns-hovered {
  @include customStyles(auto);
}

.kanban-container {
  width: 100%;
  max-width: 98%;
  border-radius: 5px;
  padding: 5px;
  min-height: 62vh;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  position: absolute;
  @media (max-width: 576px) {
    padding: 0px;
    margin: 0px;
  }
}

.search-kanban {
  margin-top: 0px;
  margin-left: 0px;
}

.kanban-header-filters {
  display: flex;
  @media (max-width: 576px) {
    height: 100px;
    width: 350px;
  }
}

.kanban-tittle-principal {
  font-size: 15px;
  display: flex;
  padding: 0;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  @media (max-width: 576px) {
    display: none;
  }
}
.search-users {
  display: flex;
  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.search-seller {
  display: flex;
  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.kanban-section-row {
  border: 1px solid #edf0f4;
  border-radius: 5px;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.1);
  min-height: 62vh;
}

.kanban-search {
  max-width: 250px;
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;

  @media (max-width: 576px) {
    max-width: 150px;
    min-width: 150px;
    height: 25px;
  }
}

.filter-seller {
  min-width: 250px;
  max-width: 250px;
  min-height: 20px;
  max-height: 20px;
  margin-left: 10px;

  @media (max-width: 576px) {
    max-width: 145px;
    min-width: 145px;
    margin-top: 0px;
    margin-left: 5px;
  }
}

.custom-toggle {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
  cursor: pointer;
  font-size: 20px;
}
.custom-toggle::after {
  display: none !important;
}

.kanban-columns {
  &:nth-child(n):not(:last-child) {
    margin-right: 10px;
  }
}

.switch-promise {
  min-width: 250px;

  @media (max-width: 576px) {
    max-width: 150px;
    min-width: 150px;
    margin-top: 25px;
    font-size: 12px;
  }
}

.kanban-section-tittle {
  background-color: white;
  font-weight: normal;
  font-family: sans-serif;
  border-radius: 5px;
  border: 1px solid #edf0f4;
}

.kanban-section-name {
  font-size: 12px;
  text-overflow: unset;

  @media (max-width: 576px) {
    font-size: 11px;
  }
}

.kanban-tittle-container {
  flex-wrap: wrap;
}

.kanban-section-total {
  font-weight: bold;
  text-overflow: ellipsis;
  @media (max-width: 576px) {
    font-size: 11px;
  }
}

.kanban-icon-dropdown {
  font-size: 15px;
  cursor: pointer;
}

.kanban-column-container {
  background-color: white;
  font-size: 12px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #edf0f4;
}

.kanban-column-footer {
  border-radius: 5px;
  width: 180px;
  left: 5px;
  position: relative;
  border: 1px solid #edf0f4;
  box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.1);
}

.kanban-column-name {
  font-size: 12px;
  @media (max-width: 576px) {
    font-size: 11px;
  }
}

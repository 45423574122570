.purchase-management {
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-bottom: 1rem;

    button {
      margin-bottom: 1rem;
    }
  }

  .box-info {
    display: flex;
    align-items: center;
    font-weight: normal;

    p {
      color: $gray-700;
      margin-bottom: 0.5rem;

      .box-title {
        color: $gray-900;
        font-weight: bold;
      }
    }
  }

  &__cards {
    margin-top: 1.5rem;

    .card-detail {
      margin-bottom: 1.5rem;
      font-weight: bold;

      &__state-msg {
        text-align: end;
        margin-bottom: 0.5rem;
      }

      &__state-btn {
        display: flex;
        justify-content: flex-end;
      }

      &__title {
        display: flex;
        align-items: center;
        text-transform: uppercase;

        h3 {
          margin-bottom: 0;
        }
      }

      .box-info {
        p {
          margin-bottom: 1rem;

          .box-title {
            display: grid;
          }
        }

        .box-non-grid {
          display: flex;
          text-transform: uppercase;
          font-weight: bold;
          color: $gray-900;

          .box-title {
            margin-right: 0.5rem;
          }
        }
      }

      &__detail-btn {
        align-self: flex-start;

        button {
          font-weight: bold;
        }
      }
    }
  }
}
